import React from 'react'

class ErrorPage extends React.Component {

  render() {
      return (
          <div>
              <h1>404 Page Not Found</h1>
          </div>
      );
  }
}

export default ErrorPage
